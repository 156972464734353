import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const About = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="About" />

      <h1 className="text-center mt-2 mb-4">About this site</h1>
      <div className="about">
        <p className="mb-4">
          This site contains a variety of audio recordings of Bible classes and sermons, primarily recorded at Benchley church of Christ just north of Bryan, TX.  These recordings are available as podcast feeds (see footer below for links) and can also be listened to directly on the site or freely downloaded as MP3 files.  This site contains recordings as early as April 2010.
        </p>

        <p className="mb-4">
          The notes and recordings presented here are published in the hope that they will help people find the truth about God and what His purpose is for us.  It is extremely important that you read and study for yourself and not simply take in whatever is presented here or elsewhere without sufficient evidence.  There are many falsehoods in the world, but only one truth!
        </p>

        <p className="mb-4">
          Please take what you see here, study it, share it with family and friends and find the truth for yourself.  Feel free to post comments on our Discord server (link below) if you have questions or want to study further.
        </p>

        <p className="mb-4">
          This site is operated by Mark Watson.  You can reach him at the ScriptureStream <a href="https://discord.gg/2gZRUWUrWf">Discord server</a> or on the <a href="https://keybase.io/team/scripturestream">scripturestream team on Keybase</a>.
        </p>

        
        <p className="mt-4 mb-4 text-center">
          <span className="text-bold">Contents:</span><br/>
          {data.posts.totalCount} lessons<br/>
          {data.topics.totalCount} topics<br/>
          {data.series.totalCount} series<br/>
          {data.presenters.totalCount} presenters<br/>
        </p>

        <p className="mb-4 text-center">
          Last built: {new Date().toDateString()}
        </p>
      </div>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query AboutPage {
    site {
      siteMetadata {
        title
      }
    }
    presenters: allFile(
      filter: {sourceInstanceName: {eq: "presenters"}}
    ) {
      totalCount
    }
    posts: allFile(filter: { sourceInstanceName: { eq: "posts"} }) {
      totalCount
    }
    topics: allFile(filter: { sourceInstanceName: { eq: "topics"} }) {
      totalCount
    }
    series: allFile(filter: { sourceInstanceName: { eq: "series"} }) {
      totalCount
    }
  }
`
